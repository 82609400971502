*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(30 200 255 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(30 200 255 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
}
.container {
    width: 100%;
}
@media (min-width: 0px) {
    .container {
        max-width: 0px;
    }
}
@media (min-width: 300px) {
    .container {
        max-width: 300px;
    }
}
@media (min-width: 400px) {
    .container {
        max-width: 400px;
    }
}
@media (min-width: 600px) {
    .container {
        max-width: 600px;
    }
}
@media (min-width: 905px) {
    .container {
        max-width: 905px;
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1240px) {
    .container {
        max-width: 1240px;
    }
}
@media (min-width: 1440px) {
    .container {
        max-width: 1440px;
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}
@media (min-width: 2500px) {
    .container {
        max-width: 2500px;
    }
}
body :is(.pointer-events-none) {
    pointer-events: none;
}
body :is(.invisible) {
    visibility: hidden;
}
body :is(.fixed) {
    position: fixed;
}
body :is(.absolute) {
    position: absolute;
}
body :is(.relative) {
    position: relative;
}
body :is(.sticky) {
    position: sticky;
}
body :is(.inset-0) {
    inset: 0px;
}
body :is(.-bottom-2) {
    bottom: -0.5rem;
}
body :is(.-bottom-3) {
    bottom: -0.75rem;
}
body :is(.-bottom-\[100px\]) {
    bottom: -100px;
}
body :is(.-bottom-\[110px\]) {
    bottom: -110px;
}
body :is(.-bottom-\[320px\]) {
    bottom: -320px;
}
body :is(.-bottom-\[5px\]) {
    bottom: -5px;
}
body :is(.-bottom-\[80px\]) {
    bottom: -80px;
}
body :is(.-left-20) {
    left: -5rem;
}
body :is(.-left-\[4\.5px\]) {
    left: -4.5px;
}
body :is(.-right-4) {
    right: -1rem;
}
body :is(.-right-80) {
    right: -20rem;
}
body :is(.-right-\[0\.1rem\]) {
    right: -0.1rem;
}
body :is(.-top-1) {
    top: -0.25rem;
}
body :is(.-top-12) {
    top: -3rem;
}
body :is(.-top-20) {
    top: -5rem;
}
body :is(.-top-24) {
    top: -6rem;
}
body :is(.-top-40) {
    top: -10rem;
}
body :is(.-top-9) {
    top: -2.25rem;
}
body :is(.-top-\[0\.1rem\]) {
    top: -0.1rem;
}
body :is(.-top-\[0px\]) {
    top: -0px;
}
body :is(.-top-\[100px\]) {
    top: -100px;
}
body :is(.-top-\[40px\]) {
    top: -40px;
}
body :is(.bottom-0) {
    bottom: 0px;
}
body :is(.bottom-10) {
    bottom: 2.5rem;
}
body :is(.bottom-14) {
    bottom: 3.5rem;
}
body :is(.bottom-2) {
    bottom: 0.5rem;
}
body :is(.bottom-7) {
    bottom: 1.75rem;
}
body :is(.left-0) {
    left: 0px;
}
body :is(.left-1\/2) {
    left: 50%;
}
body :is(.left-2) {
    left: 0.5rem;
}
body :is(.left-2\/4) {
    left: 50%;
}
body :is(.left-3) {
    left: 0.75rem;
}
body :is(.left-4) {
    left: 1rem;
}
body :is(.left-\[50\%\]) {
    left: 50%;
}
body :is(.right-0) {
    right: 0px;
}
body :is(.right-1\/2) {
    right: 50%;
}
body :is(.right-10) {
    right: 2.5rem;
}
body :is(.right-2) {
    right: 0.5rem;
}
body :is(.right-2\/4) {
    right: 50%;
}
body :is(.right-24) {
    right: 6rem;
}
body :is(.right-3) {
    right: 0.75rem;
}
body :is(.right-4) {
    right: 1rem;
}
body :is(.right-5) {
    right: 1.25rem;
}
body :is(.right-6) {
    right: 1.5rem;
}
body :is(.right-7) {
    right: 1.75rem;
}
body :is(.right-full) {
    right: 100%;
}
body :is(.top-0) {
    top: 0px;
}
body :is(.top-1\/2) {
    top: 50%;
}
body :is(.top-12) {
    top: 3rem;
}
body :is(.top-16) {
    top: 4rem;
}
body :is(.top-2) {
    top: 0.5rem;
}
body :is(.top-20) {
    top: 5rem;
}
body :is(.top-3) {
    top: 0.75rem;
}
body :is(.top-32) {
    top: 8rem;
}
body :is(.top-36) {
    top: 9rem;
}
body :is(.top-4) {
    top: 1rem;
}
body :is(.top-6) {
    top: 1.5rem;
}
body :is(.top-8) {
    top: 2rem;
}
body :is(.top-\[100\%\]) {
    top: 100%;
}
body :is(.top-\[100px\]) {
    top: 100px;
}
body :is(.top-\[140px\]) {
    top: 140px;
}
body :is(.top-\[16px\]) {
    top: 16px;
}
body :is(.top-\[50\%\]) {
    top: 50%;
}
body :is(.z-0) {
    z-index: 0;
}
body :is(.z-10) {
    z-index: 10;
}
body :is(.z-20) {
    z-index: 20;
}
body :is(.z-30) {
    z-index: 30;
}
body :is(.z-40) {
    z-index: 40;
}
body :is(.z-50) {
    z-index: 50;
}
body :is(.z-\[1\]) {
    z-index: 1;
}
body :is(.order-first) {
    order: -9999;
}
body :is(.mx-2) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
body :is(.mx-4) {
    margin-left: 1rem;
    margin-right: 1rem;
}
body :is(.mx-5) {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
body :is(.mx-auto) {
    margin-left: auto;
    margin-right: auto;
}
body :is(.my-10) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
body :is(.my-2) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
body :is(.my-20) {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
body :is(.my-3) {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
body :is(.my-4) {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
body :is(.my-6) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
body :is(.my-\[10px\]) {
    margin-top: 10px;
    margin-bottom: 10px;
}
body :is(.my-\[36px\]) {
    margin-top: 36px;
    margin-bottom: 36px;
}
body :is(.my-auto) {
    margin-top: auto;
    margin-bottom: auto;
}
body :is(.-ml-2) {
    margin-left: -0.5rem;
}
body :is(.-mt-1) {
    margin-top: -0.25rem;
}
body :is(.mb-1) {
    margin-bottom: 0.25rem;
}
body :is(.mb-12) {
    margin-bottom: 3rem;
}
body :is(.mb-2) {
    margin-bottom: 0.5rem;
}
body :is(.mb-3) {
    margin-bottom: 0.75rem;
}
body :is(.mb-4) {
    margin-bottom: 1rem;
}
body :is(.mb-5) {
    margin-bottom: 1.25rem;
}
body :is(.mb-6) {
    margin-bottom: 1.5rem;
}
body :is(.mb-8) {
    margin-bottom: 2rem;
}
body :is(.mb-9) {
    margin-bottom: 2.25rem;
}
body :is(.mb-\[10px\]) {
    margin-bottom: 10px;
}
body :is(.mb-\[20px\]) {
    margin-bottom: 20px;
}
body :is(.mb-\[40px\]) {
    margin-bottom: 40px;
}
body :is(.mb-\[4px\]) {
    margin-bottom: 4px;
}
body :is(.ml-2) {
    margin-left: 0.5rem;
}
body :is(.ml-4) {
    margin-left: 1rem;
}
body :is(.ml-6) {
    margin-left: 1.5rem;
}
body :is(.ml-\[10px\]) {
    margin-left: 10px;
}
body :is(.ml-\[12px\]) {
    margin-left: 12px;
}
body :is(.ml-\[16px\]) {
    margin-left: 16px;
}
body :is(.ml-auto) {
    margin-left: auto;
}
body :is(.mr-1) {
    margin-right: 0.25rem;
}
body :is(.mr-2) {
    margin-right: 0.5rem;
}
body :is(.mr-3) {
    margin-right: 0.75rem;
}
body :is(.mr-4) {
    margin-right: 1rem;
}
body :is(.mr-5) {
    margin-right: 1.25rem;
}
body :is(.mr-8) {
    margin-right: 2rem;
}
body :is(.mr-\[12px\]) {
    margin-right: 12px;
}
body :is(.mr-\[41px\]) {
    margin-right: 41px;
}
body :is(.mr-auto) {
    margin-right: auto;
}
body :is(.mt-0) {
    margin-top: 0px;
}
body :is(.mt-1) {
    margin-top: 0.25rem;
}
body :is(.mt-10) {
    margin-top: 2.5rem;
}
body :is(.mt-12) {
    margin-top: 3rem;
}
body :is(.mt-16) {
    margin-top: 4rem;
}
body :is(.mt-176) {
    margin-top: 44rem;
}
body :is(.mt-2) {
    margin-top: 0.5rem;
}
body :is(.mt-20) {
    margin-top: 5rem;
}
body :is(.mt-3) {
    margin-top: 0.75rem;
}
body :is(.mt-4) {
    margin-top: 1rem;
}
body :is(.mt-5) {
    margin-top: 1.25rem;
}
body :is(.mt-52) {
    margin-top: 13rem;
}
body :is(.mt-6) {
    margin-top: 1.5rem;
}
body :is(.mt-7) {
    margin-top: 1.75rem;
}
body :is(.mt-8) {
    margin-top: 2rem;
}
body :is(.mt-9) {
    margin-top: 2.25rem;
}
body :is(.mt-96) {
    margin-top: 24rem;
}
body :is(.mt-\[0\.75rem\]) {
    margin-top: 0.75rem;
}
body :is(.mt-\[100px\]) {
    margin-top: 100px;
}
body :is(.mt-\[15px\]) {
    margin-top: 15px;
}
body :is(.mt-\[16px\]) {
    margin-top: 16px;
}
body :is(.mt-\[20px\]) {
    margin-top: 20px;
}
body :is(.mt-\[24px\]) {
    margin-top: 24px;
}
body :is(.mt-\[2px\]) {
    margin-top: 2px;
}
body :is(.mt-\[30px\]) {
    margin-top: 30px;
}
body :is(.mt-\[40px\]) {
    margin-top: 40px;
}
body :is(.mt-\[42px\]) {
    margin-top: 42px;
}
body :is(.mt-\[5px\]) {
    margin-top: 5px;
}
body :is(.mt-\[5rem\]) {
    margin-top: 5rem;
}
body :is(.mt-\[60px\]) {
    margin-top: 60px;
}
body :is(.mt-\[66px\]) {
    margin-top: 66px;
}
body :is(.mt-\[6px\]) {
    margin-top: 6px;
}
body :is(.mt-auto) {
    margin-top: auto;
}
body :is(.block) {
    display: block;
}
body :is(.inline-block) {
    display: inline-block;
}
body :is(.flex) {
    display: flex;
}
body :is(.table) {
    display: table;
}
body :is(.grid) {
    display: grid;
}
body :is(.hidden) {
    display: none;
}
body :is(.aspect-video) {
    aspect-ratio: 16 / 9;
}
body :is(.h-0) {
    height: 0px;
}
body :is(.h-1) {
    height: 0.25rem;
}
body :is(.h-1\/2) {
    height: 50%;
}
body :is(.h-10) {
    height: 2.5rem;
}
body :is(.h-11) {
    height: 2.75rem;
}
body :is(.h-12) {
    height: 3rem;
}
body :is(.h-14) {
    height: 3.5rem;
}
body :is(.h-16) {
    height: 4rem;
}
body :is(.h-2) {
    height: 0.5rem;
}
body :is(.h-2\/3) {
    height: 66.666667%;
}
body :is(.h-20) {
    height: 5rem;
}
body :is(.h-24) {
    height: 6rem;
}
body :is(.h-28) {
    height: 7rem;
}
body :is(.h-32) {
    height: 8rem;
}
body :is(.h-36) {
    height: 9rem;
}
body :is(.h-4) {
    height: 1rem;
}
body :is(.h-40) {
    height: 10rem;
}
body :is(.h-44) {
    height: 11rem;
}
body :is(.h-48) {
    height: 12rem;
}
body :is(.h-5) {
    height: 1.25rem;
}
body :is(.h-52) {
    height: 13rem;
}
body :is(.h-6) {
    height: 1.5rem;
}
body :is(.h-60) {
    height: 15rem;
}
body :is(.h-64) {
    height: 16rem;
}
body :is(.h-7) {
    height: 1.75rem;
}
body :is(.h-8) {
    height: 2rem;
}
body :is(.h-80) {
    height: 20rem;
}
body :is(.h-9) {
    height: 2.25rem;
}
body :is(.h-96) {
    height: 24rem;
}
body :is(.h-\[0\.05rem\]) {
    height: 0.05rem;
}
body :is(.h-\[0\.2rem\]) {
    height: 0.2rem;
}
body :is(.h-\[0\.5px\]) {
    height: 0.5px;
}
body :is(.h-\[100px\]) {
    height: 100px;
}
body :is(.h-\[100vh\]) {
    height: 100vh;
}
body :is(.h-\[10px\]) {
    height: 10px;
}
body :is(.h-\[120px\]) {
    height: 120px;
}
body :is(.h-\[124px\]) {
    height: 124px;
}
body :is(.h-\[128px\]) {
    height: 128px;
}
body :is(.h-\[12px\]) {
    height: 12px;
}
body :is(.h-\[14px\]) {
    height: 14px;
}
body :is(.h-\[15\.7rem\]) {
    height: 15.7rem;
}
body :is(.h-\[150px\]) {
    height: 150px;
}
body :is(.h-\[15px\]) {
    height: 15px;
}
body :is(.h-\[16px\]) {
    height: 16px;
}
body :is(.h-\[17\.5rem\]) {
    height: 17.5rem;
}
body :is(.h-\[1px\]) {
    height: 1px;
}
body :is(.h-\[2\.25rem\]) {
    height: 2.25rem;
}
body :is(.h-\[20\.5rem\]) {
    height: 20.5rem;
}
body :is(.h-\[20px\]) {
    height: 20px;
}
body :is(.h-\[22px\]) {
    height: 22px;
}
body :is(.h-\[24px\]) {
    height: 24px;
}
body :is(.h-\[250px\]) {
    height: 250px;
}
body :is(.h-\[28px\]) {
    height: 28px;
}
body :is(.h-\[3\.125rem\]) {
    height: 3.125rem;
}
body :is(.h-\[300px\]) {
    height: 300px;
}
body :is(.h-\[350px\]) {
    height: 350px;
}
body :is(.h-\[37px\]) {
    height: 37px;
}
body :is(.h-\[39rem\]) {
    height: 39rem;
}
body :is(.h-\[40px\]) {
    height: 40px;
}
body :is(.h-\[40rem\]) {
    height: 40rem;
}
body :is(.h-\[41rem\]) {
    height: 41rem;
}
body :is(.h-\[43rem\]) {
    height: 43rem;
}
body :is(.h-\[44px\]) {
    height: 44px;
}
body :is(.h-\[450px\]) {
    height: 450px;
}
body :is(.h-\[46px\]) {
    height: 46px;
}
body :is(.h-\[480px\]) {
    height: 480px;
}
body :is(.h-\[4rem\]) {
    height: 4rem;
}
body :is(.h-\[5\.5rem\]) {
    height: 5.5rem;
}
body :is(.h-\[50px\]) {
    height: 50px;
}
body :is(.h-\[50rem\]) {
    height: 50rem;
}
body :is(.h-\[50vh\]) {
    height: 50vh;
}
body :is(.h-\[56px\]) {
    height: 56px;
}
body :is(.h-\[70vh\]) {
    height: 70vh;
}
body :is(.h-\[72px\]) {
    height: 72px;
}
body :is(.h-\[80px\]) {
    height: 80px;
}
body :is(.h-\[84px\]) {
    height: 84px;
}
body :is(.h-\[8px\]) {
    height: 8px;
}
body :is(.h-\[90\%\]) {
    height: 90%;
}
body :is(.h-\[90vh\]) {
    height: 90vh;
}
body :is(.h-\[95px\]) {
    height: 95px;
}
body :is(.h-\[95vh\]) {
    height: 95vh;
}
body :is(.h-\[calc\(100vh-84px\)\]) {
    height: calc(100vh - 84px);
}
body :is(.h-auto) {
    height: auto;
}
body :is(.h-full) {
    height: 100%;
}
body :is(.h-screen) {
    height: 100vh;
}
body :is(.max-h-36) {
    max-height: 9rem;
}
body :is(.max-h-96) {
    max-height: 24rem;
}
body :is(.max-h-\[270px\]) {
    max-height: 270px;
}
body :is(.max-h-\[362px\]) {
    max-height: 362px;
}
body :is(.max-h-\[400px\]) {
    max-height: 400px;
}
body :is(.max-h-\[40vh\]) {
    max-height: 40vh;
}
body :is(.max-h-\[50vh\]) {
    max-height: 50vh;
}
body :is(.max-h-\[70vh\]) {
    max-height: 70vh;
}
body :is(.max-h-\[80vh\]) {
    max-height: 80vh;
}
body :is(.max-h-\[90vh\]) {
    max-height: 90vh;
}
body :is(.min-h-\[100vh\]) {
    min-height: 100vh;
}
body :is(.min-h-\[14rem\]) {
    min-height: 14rem;
}
body :is(.min-h-\[45rem\]) {
    min-height: 45rem;
}
body :is(.min-h-\[80vh\]) {
    min-height: 80vh;
}
body :is(.min-h-screen) {
    min-height: 100vh;
}
body :is(.w-1) {
    width: 0.25rem;
}
body :is(.w-1\/2) {
    width: 50%;
}
body :is(.w-1\/3) {
    width: 33.333333%;
}
body :is(.w-1\/4) {
    width: 25%;
}
body :is(.w-10) {
    width: 2.5rem;
}
body :is(.w-12) {
    width: 3rem;
}
body :is(.w-128) {
    width: 32rem;
}
body :is(.w-14) {
    width: 3.5rem;
}
body :is(.w-16) {
    width: 4rem;
}
body :is(.w-2) {
    width: 0.5rem;
}
body :is(.w-2\/3) {
    width: 66.666667%;
}
body :is(.w-2\/4) {
    width: 50%;
}
body :is(.w-20) {
    width: 5rem;
}
body :is(.w-24) {
    width: 6rem;
}
body :is(.w-28) {
    width: 7rem;
}
body :is(.w-3) {
    width: 0.75rem;
}
body :is(.w-3\/4) {
    width: 75%;
}
body :is(.w-32) {
    width: 8rem;
}
body :is(.w-36) {
    width: 9rem;
}
body :is(.w-4) {
    width: 1rem;
}
body :is(.w-40) {
    width: 10rem;
}
body :is(.w-44) {
    width: 11rem;
}
body :is(.w-48) {
    width: 12rem;
}
body :is(.w-5) {
    width: 1.25rem;
}
body :is(.w-52) {
    width: 13rem;
}
body :is(.w-56) {
    width: 14rem;
}
body :is(.w-6) {
    width: 1.5rem;
}
body :is(.w-60) {
    width: 15rem;
}
body :is(.w-64) {
    width: 16rem;
}
body :is(.w-7) {
    width: 1.75rem;
}
body :is(.w-72) {
    width: 18rem;
}
body :is(.w-8) {
    width: 2rem;
}
body :is(.w-80) {
    width: 20rem;
}
body :is(.w-85) {
    width: 22.5rem;
}
body :is(.w-96) {
    width: 24rem;
}
body :is(.w-\[0\.5px\]) {
    width: 0.5px;
}
body :is(.w-\[0px\]) {
    width: 0px;
}
body :is(.w-\[100\%\]) {
    width: 100%;
}
body :is(.w-\[100px\]) {
    width: 100px;
}
body :is(.w-\[10rem\]) {
    width: 10rem;
}
body :is(.w-\[120px\]) {
    width: 120px;
}
body :is(.w-\[124px\]) {
    width: 124px;
}
body :is(.w-\[128px\]) {
    width: 128px;
}
body :is(.w-\[14rem\]) {
    width: 14rem;
}
body :is(.w-\[15\.7rem\]) {
    width: 15.7rem;
}
body :is(.w-\[150px\]) {
    width: 150px;
}
body :is(.w-\[160px\]) {
    width: 160px;
}
body :is(.w-\[16px\]) {
    width: 16px;
}
body :is(.w-\[17rem\]) {
    width: 17rem;
}
body :is(.w-\[18\%\]) {
    width: 18%;
}
body :is(.w-\[200px\]) {
    width: 200px;
}
body :is(.w-\[20rem\]) {
    width: 20rem;
}
body :is(.w-\[225px\]) {
    width: 225px;
}
body :is(.w-\[22rem\]) {
    width: 22rem;
}
body :is(.w-\[24px\]) {
    width: 24px;
}
body :is(.w-\[28px\]) {
    width: 28px;
}
body :is(.w-\[2px\]) {
    width: 2px;
}
body :is(.w-\[3\.125rem\]) {
    width: 3.125rem;
}
body :is(.w-\[3\.5rem\]) {
    width: 3.5rem;
}
body :is(.w-\[30\%\]) {
    width: 30%;
}
body :is(.w-\[30px\]) {
    width: 30px;
}
body :is(.w-\[325px\]) {
    width: 325px;
}
body :is(.w-\[32px\]) {
    width: 32px;
}
body :is(.w-\[35rem\]) {
    width: 35rem;
}
body :is(.w-\[40px\]) {
    width: 40px;
}
body :is(.w-\[40rem\]) {
    width: 40rem;
}
body :is(.w-\[50px\]) {
    width: 50px;
}
body :is(.w-\[56px\]) {
    width: 56px;
}
body :is(.w-\[60px\]) {
    width: 60px;
}
body :is(.w-\[6px\]) {
    width: 6px;
}
body :is(.w-\[70vw\]) {
    width: 70vw;
}
body :is(.w-\[72px\]) {
    width: 72px;
}
body :is(.w-\[75\%\]) {
    width: 75%;
}
body :is(.w-\[80px\]) {
    width: 80px;
}
body :is(.w-\[80rem\]) {
    width: 80rem;
}
body :is(.w-\[82px\]) {
    width: 82px;
}
body :is(.w-\[84px\]) {
    width: 84px;
}
body :is(.w-\[86px\]) {
    width: 86px;
}
body :is(.w-\[94\%\]) {
    width: 94%;
}
body :is(.w-\[95\%\]) {
    width: 95%;
}
body :is(.w-\[96\%\]) {
    width: 96%;
}
body :is(.w-\[calc\(100\%-6\.2rem\)\]) {
    width: calc(100% - 6.2rem);
}
body :is(.w-\[calc\(94\%\)\]) {
    width: calc(94%);
}
body :is(.w-auto) {
    width: auto;
}
body :is(.w-full) {
    width: 100%;
}
body :is(.w-screen) {
    width: 100vw;
}
body :is(.min-w-0) {
    min-width: 0px;
}
body :is(.max-w-3xl) {
    max-width: 48rem;
}
body :is(.max-w-4xl) {
    max-width: 56rem;
}
body :is(.max-w-5xl) {
    max-width: 64rem;
}
body :is(.max-w-60) {
    max-width:  15rem;
}
body :is(.max-w-\[100px\]) {
    max-width: 100px;
}
body :is(.max-w-\[1600px\]) {
    max-width: 1600px;
}
body :is(.max-w-\[26rem\]) {
    max-width: 26rem;
}
body :is(.max-w-\[28rem\]) {
    max-width: 28rem;
}
body :is(.max-w-\[30rem\]) {
    max-width: 30rem;
}
body :is(.max-w-\[80rem\]) {
    max-width: 80rem;
}
body :is(.max-w-lg) {
    max-width: 32rem;
}
body :is(.max-w-sm) {
    max-width: 24rem;
}
body :is(.max-w-xs) {
    max-width: 20rem;
}
body :is(.flex-1) {
    flex: 1 1 0%;
}
body :is(.flex-\[2\]) {
    flex: 2;
}
body :is(.flex-auto) {
    flex: 1 1 auto;
}
body :is(.flex-shrink-0) {
    flex-shrink: 0;
}
body :is(.flex-grow) {
    flex-grow: 1;
}
body :is(.-translate-x-1\/2) {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body :is(.-translate-y-1\/2) {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body :is(.translate-x-1\/2) {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body :is(.rotate-180) {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body :is(.scale-\[0\.8\]) {
    --tw-scale-x: 0.8;
    --tw-scale-y: 0.8;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body :is(.scale-\[4\]) {
    --tw-scale-x: 4;
    --tw-scale-y: 4;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
body :is(.transform) {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes pulse {
    50% {
        opacity: .5;
    }
}
body :is(.animate-pulse) {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
body :is(.animate-spin) {
    animation: spin 1s linear infinite;
}
body :is(.cursor-auto) {
    cursor: auto;
}
body :is(.cursor-default) {
    cursor: default;
}
body :is(.cursor-pointer) {
    cursor: pointer;
}
body :is(.resize-none) {
    resize: none;
}
body :is(.resize) {
    resize: both;
}
body :is(.appearance-none) {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
body :is(.grid-cols-1) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
body :is(.grid-cols-2) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
body :is(.grid-cols-3) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
body :is(.flex-row) {
    flex-direction: row;
}
body :is(.flex-col) {
    flex-direction: column;
}
body :is(.flex-col-reverse) {
    flex-direction: column-reverse;
}
body :is(.flex-wrap) {
    flex-wrap: wrap;
}
body :is(.items-start) {
    align-items: flex-start;
}
body :is(.items-end) {
    align-items: flex-end;
}
body :is(.items-center) {
    align-items: center;
}
body :is(.items-stretch) {
    align-items: stretch;
}
body :is(.justify-start) {
    justify-content: flex-start;
}
body :is(.justify-end) {
    justify-content: flex-end;
}
body :is(.justify-center) {
    justify-content: center;
}
body :is(.justify-between) {
    justify-content: space-between;
}
body :is(.justify-around) {
    justify-content: space-around;
}
body :is(.justify-items-start) {
    justify-items: start;
}
body :is(.justify-items-center) {
    justify-items: center;
}
body :is(.gap-1) {
    gap: 0.25rem;
}
body :is(.gap-10) {
    gap: 2.5rem;
}
body :is(.gap-2) {
    gap: 0.5rem;
}
body :is(.gap-3) {
    gap: 0.75rem;
}
body :is(.gap-4) {
    gap: 1rem;
}
body :is(.gap-5) {
    gap: 1.25rem;
}
body :is(.gap-6) {
    gap: 1.5rem;
}
body :is(.gap-\[16px\]) {
    gap: 16px;
}
body :is(.gap-\[7rem\]) {
    gap: 7rem;
}
body :is(.gap-x-1) {
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
body :is(.gap-x-3) {
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
}
body :is(.gap-x-5) {
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
}
body :is(.gap-x-\[24px\]) {
    -moz-column-gap: 24px;
         column-gap: 24px;
}
body :is(.gap-y-10) {
    row-gap: 2.5rem;
}
body :is(.gap-y-2) {
    row-gap: 0.5rem;
}
body :is(.gap-y-6) {
    row-gap: 1.5rem;
}
body :is(.gap-y-9) {
    row-gap: 2.25rem;
}
body :is(.gap-y-\[40px\]) {
    row-gap: 40px;
}
body :is(.space-x-1 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-10 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-16 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-2 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-3 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-4 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-6 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-7 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-8 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-9 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[0\.625rem\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[0\.6rem\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.6rem * var(--tw-space-x-reverse));
    margin-left: calc(0.6rem * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[10px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10px * var(--tw-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[12px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12px * var(--tw-space-x-reverse));
    margin-left: calc(12px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[16px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16px * var(--tw-space-x-reverse));
    margin-left: calc(16px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[20px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20px * var(--tw-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[22px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(22px * var(--tw-space-x-reverse));
    margin-left: calc(22px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[2px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2px * var(--tw-space-x-reverse));
    margin-left: calc(2px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-x-\[8px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8px * var(--tw-space-x-reverse));
    margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
}
body :is(.space-y-0 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
body :is(.space-y-1 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
body :is(.space-y-10 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
body :is(.space-y-12 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
body :is(.space-y-2 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
body :is(.space-y-3 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
body :is(.space-y-3\.5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
}
body :is(.space-y-4 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
body :is(.space-y-5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
body :is(.space-y-6 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
body :is(.space-y-7 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}
body :is(.space-y-8 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
body :is(.space-y-\[100px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(100px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[10px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[12px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[13px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[14px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[16px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[18px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[20px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[23px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(23px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(23px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[24px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[26px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(26px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(26px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[28px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(28px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(28px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[30px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(30px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[32px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(32px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(32px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[34px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(34px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(34px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[40px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(40px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[4px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[5px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[64px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(64px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(64px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[6px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[80px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(80px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[8px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8px * var(--tw-space-y-reverse));
}
body :is(.space-y-\[9px\] > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9px * var(--tw-space-y-reverse));
}
body :is(.place-self-end) {
    place-self: end;
}
body :is(.self-end) {
    align-self: flex-end;
}
body :is(.self-center) {
    align-self: center;
}
body :is(.justify-self-end) {
    justify-self: end;
}
body :is(.overflow-auto) {
    overflow: auto;
}
body :is(.overflow-hidden) {
    overflow: hidden;
}
body :is(.overflow-visible) {
    overflow: visible;
}
body :is(.overflow-scroll) {
    overflow: scroll;
}
body :is(.overflow-y-auto) {
    overflow-y: auto;
}
body :is(.overflow-x-hidden) {
    overflow-x: hidden;
}
body :is(.overflow-y-hidden) {
    overflow-y: hidden;
}
body :is(.overflow-x-scroll) {
    overflow-x: scroll;
}
body :is(.overflow-y-scroll) {
    overflow-y: scroll;
}
body :is(.overflow-ellipsis) {
    text-overflow: ellipsis;
}
body :is(.whitespace-normal) {
    white-space: normal;
}
body :is(.whitespace-nowrap) {
    white-space: nowrap;
}
body :is(.break-words) {
    overflow-wrap: break-word;
}
body :is(.rounded) {
    border-radius: 0.25rem;
}
body :is(.rounded-2xl) {
    border-radius: 1rem;
}
body :is(.rounded-3xl) {
    border-radius: 1.5rem;
}
body :is(.rounded-4xl) {
    border-radius: 4rem;
}
body :is(.rounded-\[1\.75rem\]) {
    border-radius: 1.75rem;
}
body :is(.rounded-\[10px\]) {
    border-radius: 10px;
}
body :is(.rounded-\[12px\]) {
    border-radius: 12px;
}
body :is(.rounded-\[14px\]) {
    border-radius: 14px;
}
body :is(.rounded-\[16px\]) {
    border-radius: 16px;
}
body :is(.rounded-\[20px\]) {
    border-radius: 20px;
}
body :is(.rounded-\[24px\]) {
    border-radius: 24px;
}
body :is(.rounded-\[28px\]) {
    border-radius: 28px;
}
body :is(.rounded-\[2rem\]) {
    border-radius: 2rem;
}
body :is(.rounded-\[32px\]) {
    border-radius: 32px;
}
body :is(.rounded-\[60px\]) {
    border-radius: 60px;
}
body :is(.rounded-\[8px\]) {
    border-radius: 8px;
}
body :is(.rounded-full) {
    border-radius: 9999px;
}
body :is(.rounded-lg) {
    border-radius: 0.5rem;
}
body :is(.rounded-none) {
    border-radius: 0px;
}
body :is(.rounded-xl) {
    border-radius: 0.75rem;
}
body :is(.rounded-b) {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}
body :is(.rounded-b-2xl) {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
body :is(.rounded-b-\[16px\]) {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
body :is(.rounded-b-\[28px\]) {
    border-bottom-right-radius: 28px;
    border-bottom-left-radius: 28px;
}
body :is(.rounded-b-lg) {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
body :is(.rounded-b-none) {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
body :is(.rounded-l-2xl) {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}
body :is(.rounded-l-lg) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
body :is(.rounded-l-none) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
body :is(.rounded-r-2xl) {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
body :is(.rounded-r-\[4px\]) {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
body :is(.rounded-r-lg) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
body :is(.rounded-t) {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}
body :is(.rounded-t-2xl) {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
body :is(.rounded-t-3xl) {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
}
body :is(.rounded-t-\[28px\]) {
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
}
body :is(.rounded-t-\[8px\]) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
body :is(.rounded-t-lg) {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
body :is(.rounded-bl-\[24px\]) {
    border-bottom-left-radius: 24px;
}
body :is(.rounded-bl-\[8px\]) {
    border-bottom-left-radius: 8px;
}
body :is(.rounded-tr-\[10px\]) {
    border-top-right-radius: 10px;
}
body :is(.rounded-tr-\[22px\]) {
    border-top-right-radius: 22px;
}
body :is(.rounded-tr-\[24px\]) {
    border-top-right-radius: 24px;
}
body :is(.border) {
    border-width: 1px;
}
body :is(.border-2) {
    border-width: 2px;
}
body :is(.border-4) {
    border-width: 4px;
}
body :is(.border-8) {
    border-width: 8px;
}
body :is(.border-\[0px\]) {
    border-width: 0px;
}
body :is(.border-b) {
    border-bottom-width: 1px;
}
body :is(.border-b-4) {
    border-bottom-width: 4px;
}
body :is(.border-t) {
    border-top-width: 1px;
}
body :is(.border-solid) {
    border-style: solid;
}
body :is(.border-none) {
    border-style: none;
}
body :is(.border-black-600) {
    --tw-border-opacity: 1;
    border-color: rgb(21 22 22 / var(--tw-border-opacity));
}
body :is(.border-black-700) {
    --tw-border-opacity: 1;
    border-color: rgb(24 24 29 / var(--tw-border-opacity));
}
body :is(.border-black-900) {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
body :is(.border-blue-500) {
    --tw-border-opacity: 1;
    border-color: rgb(30 200 255 / var(--tw-border-opacity));
}
body :is(.border-gray-200) {
    --tw-border-opacity: 1;
    border-color: rgb(49 49 55 / var(--tw-border-opacity));
}
body :is(.border-gray-500) {
    --tw-border-opacity: 1;
    border-color: rgb(184 184 184 / var(--tw-border-opacity));
}
body :is(.border-gray-550) {
    --tw-border-opacity: 1;
    border-color: rgb(96 96 96 / var(--tw-border-opacity));
}
body :is(.border-gray-700) {
    --tw-border-opacity: 1;
    border-color: rgb(166 166 166 / var(--tw-border-opacity));
}
body :is(.border-gray-900) {
    --tw-border-opacity: 1;
    border-color: rgb(39 39 44 / var(--tw-border-opacity));
}
body :is(.border-transparent) {
    border-color: transparent;
}
body :is(.border-white) {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
body :is(.bg-black-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(34 34 40 / var(--tw-bg-opacity));
}
body :is(.bg-black-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(44 44 51 / var(--tw-bg-opacity));
}
body :is(.bg-black-600) {
    --tw-bg-opacity: 1;
    background-color: rgb(21 22 22 / var(--tw-bg-opacity));
}
body :is(.bg-black-700) {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 29 / var(--tw-bg-opacity));
}
body :is(.bg-black-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(11 11 13 / var(--tw-bg-opacity));
}
body :is(.bg-black-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
body :is(.bg-blue-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}
body :is(.bg-blue-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(4 24 31 / var(--tw-bg-opacity));
}
body :is(.bg-blue-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 200 255 / var(--tw-bg-opacity));
}
body :is(.bg-gray-100) {
    --tw-bg-opacity: 1;
    background-color: rgb(33 33 38 / var(--tw-bg-opacity));
}
body :is(.bg-gray-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(49 49 55 / var(--tw-bg-opacity));
}
body :is(.bg-gray-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 55 66 / var(--tw-bg-opacity));
}
body :is(.bg-gray-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
body :is(.bg-gray-900) {
    --tw-bg-opacity: 1;
    background-color: rgb(39 39 44 / var(--tw-bg-opacity));
}
body :is(.bg-gray-925) {
    --tw-bg-opacity: 1;
    background-color: rgb(37 37 45 / var(--tw-bg-opacity));
}
body :is(.bg-green-300) {
    --tw-bg-opacity: 1;
    background-color: rgb(126 203 79 / var(--tw-bg-opacity));
}
body :is(.bg-green-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}
body :is(.bg-red-500) {
    --tw-bg-opacity: 1;
    background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
body :is(.bg-transparent) {
    background-color: transparent;
}
body :is(.bg-white) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
body :is(.bg-opacity-20) {
    --tw-bg-opacity: 0.2;
}
body :is(.bg-opacity-60) {
    --tw-bg-opacity: 0.6;
}
body :is(.bg-opacity-70) {
    --tw-bg-opacity: 0.7;
}
body :is(.bg-opacity-80) {
    --tw-bg-opacity: 0.8;
}
body :is(.bg-opacity-95) {
    --tw-bg-opacity: 0.95;
}
body :is(.bg-gradient-to-b) {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
body :is(.bg-gradient-to-r) {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
body :is(.bg-home-bg) {
    background-image: url('https://i.ibb.co/mXXGyR1/1-Lateral-slide-copy.jpg');
}
body :is(.bg-none) {
    background-image: none;
}
body :is(.from-black-700) {
    --tw-gradient-from: #18181D var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(24 24 29 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
body :is(.from-black-900) {
    --tw-gradient-from: #000 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
body :is(.from-blue-600) {
    --tw-gradient-from: #118DD7 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(17 141 215 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
body :is(.from-gray-300) {
    --tw-gradient-from: #373742 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(55 55 66 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
body :is(.from-transparent) {
    --tw-gradient-from: transparent var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
body :is(.to-black-700) {
    --tw-gradient-to: #18181D var(--tw-gradient-to-position);
}
body :is(.to-blue-500) {
    --tw-gradient-to: #1EC8FF var(--tw-gradient-to-position);
}
body :is(.to-transparent) {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}
body :is(.bg-contain) {
    background-size: contain;
}
body :is(.bg-cover) {
    background-size: cover;
}
body :is(.bg-center) {
    background-position: center;
}
body :is(.bg-top) {
    background-position: top;
}
body :is(.bg-no-repeat) {
    background-repeat: no-repeat;
}
body :is(.object-contain) {
    -o-object-fit: contain;
       object-fit: contain;
}
body :is(.object-cover) {
    -o-object-fit: cover;
       object-fit: cover;
}
body :is(.object-top) {
    -o-object-position: top;
       object-position: top;
}
body :is(.p-0) {
    padding: 0px;
}
body :is(.p-1) {
    padding: 0.25rem;
}
body :is(.p-10) {
    padding: 2.5rem;
}
body :is(.p-16) {
    padding: 4rem;
}
body :is(.p-2) {
    padding: 0.5rem;
}
body :is(.p-3) {
    padding: 0.75rem;
}
body :is(.p-4) {
    padding: 1rem;
}
body :is(.p-5) {
    padding: 1.25rem;
}
body :is(.p-6) {
    padding: 1.5rem;
}
body :is(.p-8) {
    padding: 2rem;
}
body :is(.p-\[10px\]) {
    padding: 10px;
}
body :is(.p-\[16px\]) {
    padding: 16px;
}
body :is(.p-\[20px\]) {
    padding: 20px;
}
body :is(.p-\[24px\]) {
    padding: 24px;
}
body :is(.p-\[4px\]) {
    padding: 4px;
}
body :is(.p-\[6px\]) {
    padding: 6px;
}
body :is(.p-\[7px\]) {
    padding: 7px;
}
body :is(.p-\[8px\]) {
    padding: 8px;
}
body :is(.px-0) {
    padding-left: 0px;
    padding-right: 0px;
}
body :is(.px-1) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
body :is(.px-10) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
body :is(.px-12) {
    padding-left: 3rem;
    padding-right: 3rem;
}
body :is(.px-2) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
body :is(.px-3) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
body :is(.px-4) {
    padding-left: 1rem;
    padding-right: 1rem;
}
body :is(.px-5) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
body :is(.px-6) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
body :is(.px-8) {
    padding-left: 2rem;
    padding-right: 2rem;
}
body :is(.px-\[10px\]) {
    padding-left: 10px;
    padding-right: 10px;
}
body :is(.px-\[12px\]) {
    padding-left: 12px;
    padding-right: 12px;
}
body :is(.px-\[16px\]) {
    padding-left: 16px;
    padding-right: 16px;
}
body :is(.px-\[20px\]) {
    padding-left: 20px;
    padding-right: 20px;
}
body :is(.px-\[24px\]) {
    padding-left: 24px;
    padding-right: 24px;
}
body :is(.px-\[26px\]) {
    padding-left: 26px;
    padding-right: 26px;
}
body :is(.px-\[2px\]) {
    padding-left: 2px;
    padding-right: 2px;
}
body :is(.px-\[33px\]) {
    padding-left: 33px;
    padding-right: 33px;
}
body :is(.px-\[46px\]) {
    padding-left: 46px;
    padding-right: 46px;
}
body :is(.px-\[60px\]) {
    padding-left: 60px;
    padding-right: 60px;
}
body :is(.px-\[8px\]) {
    padding-left: 8px;
    padding-right: 8px;
}
body :is(.py-0) {
    padding-top: 0px;
    padding-bottom: 0px;
}
body :is(.py-1) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
body :is(.py-10) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
body :is(.py-16) {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
body :is(.py-2) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
body :is(.py-20) {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
body :is(.py-3) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
body :is(.py-4) {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
body :is(.py-5) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
body :is(.py-6) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
body :is(.py-8) {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
body :is(.py-\[0\.9rem\]) {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}
body :is(.py-\[0px\]) {
    padding-top: 0px;
    padding-bottom: 0px;
}
body :is(.py-\[100px\]) {
    padding-top: 100px;
    padding-bottom: 100px;
}
body :is(.py-\[10px\]) {
    padding-top: 10px;
    padding-bottom: 10px;
}
body :is(.py-\[12px\]) {
    padding-top: 12px;
    padding-bottom: 12px;
}
body :is(.py-\[16px\]) {
    padding-top: 16px;
    padding-bottom: 16px;
}
body :is(.py-\[18px\]) {
    padding-top: 18px;
    padding-bottom: 18px;
}
body :is(.py-\[20px\]) {
    padding-top: 20px;
    padding-bottom: 20px;
}
body :is(.py-\[24px\]) {
    padding-top: 24px;
    padding-bottom: 24px;
}
body :is(.py-\[32px\]) {
    padding-top: 32px;
    padding-bottom: 32px;
}
body :is(.py-\[40px\]) {
    padding-top: 40px;
    padding-bottom: 40px;
}
body :is(.py-\[46px\]) {
    padding-top: 46px;
    padding-bottom: 46px;
}
body :is(.py-\[4px\]) {
    padding-top: 4px;
    padding-bottom: 4px;
}
body :is(.py-\[8px\]) {
    padding-top: 8px;
    padding-bottom: 8px;
}
body :is(.pb-0) {
    padding-bottom: 0px;
}
body :is(.pb-10) {
    padding-bottom: 2.5rem;
}
body :is(.pb-12) {
    padding-bottom: 3rem;
}
body :is(.pb-2) {
    padding-bottom: 0.5rem;
}
body :is(.pb-20) {
    padding-bottom: 5rem;
}
body :is(.pb-4) {
    padding-bottom: 1rem;
}
body :is(.pb-48) {
    padding-bottom: 12rem;
}
body :is(.pb-5) {
    padding-bottom: 1.25rem;
}
body :is(.pb-6) {
    padding-bottom: 1.5rem;
}
body :is(.pb-8) {
    padding-bottom: 2rem;
}
body :is(.pb-\[100px\]) {
    padding-bottom: 100px;
}
body :is(.pb-\[16px\]) {
    padding-bottom: 16px;
}
body :is(.pb-\[20px\]) {
    padding-bottom: 20px;
}
body :is(.pb-\[24px\]) {
    padding-bottom: 24px;
}
body :is(.pb-\[26px\]) {
    padding-bottom: 26px;
}
body :is(.pb-\[35px\]) {
    padding-bottom: 35px;
}
body :is(.pb-\[39px\]) {
    padding-bottom: 39px;
}
body :is(.pl-0) {
    padding-left: 0px;
}
body :is(.pl-3) {
    padding-left: 0.75rem;
}
body :is(.pl-4) {
    padding-left: 1rem;
}
body :is(.pl-5) {
    padding-left: 1.25rem;
}
body :is(.pl-\[16px\]) {
    padding-left: 16px;
}
body :is(.pl-\[20px\]) {
    padding-left: 20px;
}
body :is(.pr-0) {
    padding-right: 0px;
}
body :is(.pr-10) {
    padding-right: 2.5rem;
}
body :is(.pr-2) {
    padding-right: 0.5rem;
}
body :is(.pr-3) {
    padding-right: 0.75rem;
}
body :is(.pr-4) {
    padding-right: 1rem;
}
body :is(.pr-\[20px\]) {
    padding-right: 20px;
}
body :is(.pr-\[4px\]) {
    padding-right: 4px;
}
body :is(.pt-1) {
    padding-top: 0.25rem;
}
body :is(.pt-10) {
    padding-top: 2.5rem;
}
body :is(.pt-2) {
    padding-top: 0.5rem;
}
body :is(.pt-20) {
    padding-top: 5rem;
}
body :is(.pt-3) {
    padding-top: 0.75rem;
}
body :is(.pt-4) {
    padding-top: 1rem;
}
body :is(.pt-5) {
    padding-top: 1.25rem;
}
body :is(.pt-6) {
    padding-top: 1.5rem;
}
body :is(.pt-8) {
    padding-top: 2rem;
}
body :is(.pt-\[6px\]) {
    padding-top: 6px;
}
body :is(.pt-\[70px\]) {
    padding-top: 70px;
}
body :is(.pt-\[80px\]) {
    padding-top: 80px;
}
body :is(.text-left) {
    text-align: left;
}
body :is(.text-center) {
    text-align: center;
}
body :is(.text-2xl) {
    font-size: 1.5rem;
    line-height: 2rem;
}
body :is(.text-2xs) {
    font-size: 0.625rem;
}
body :is(.text-3xl) {
    font-size: 1.875rem;
    line-height: 2.25rem;
}
body :is(.text-4xl) {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
body :is(.text-5xl) {
    font-size: 3rem;
    line-height: 1;
}
body :is(.text-9xl) {
    font-size: 8rem;
    line-height: 1;
}
body :is(.text-\[1\.375rem\]) {
    font-size: 1.375rem;
}
body :is(.text-\[1\.75rem\]) {
    font-size: 1.75rem;
}
body :is(.text-\[11px\]) {
    font-size: 11px;
}
body :is(.text-\[12px\]) {
    font-size: 12px;
}
body :is(.text-\[14px\]) {
    font-size: 14px;
}
body :is(.text-\[15px\]) {
    font-size: 15px;
}
body :is(.text-\[16px\]) {
    font-size: 16px;
}
body :is(.text-\[18px\]) {
    font-size: 18px;
}
body :is(.text-\[2\.5rem\]) {
    font-size: 2.5rem;
}
body :is(.text-\[2\.75rem\]) {
    font-size: 2.75rem;
}
body :is(.text-\[20px\]) {
    font-size: 20px;
}
body :is(.text-\[22px\]) {
    font-size: 22px;
}
body :is(.text-\[24px\]) {
    font-size: 24px;
}
body :is(.text-\[28px\]) {
    font-size: 28px;
}
body :is(.text-\[30px\]) {
    font-size: 30px;
}
body :is(.text-\[32px\]) {
    font-size: 32px;
}
body :is(.text-\[36px\]) {
    font-size: 36px;
}
body :is(.text-base) {
    font-size: 1rem;
    line-height: 1.5rem;
}
body :is(.text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
body :is(.text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
body :is(.text-xl) {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
body :is(.text-xs) {
    font-size: 0.75rem;
    line-height: 1rem;
}
body :is(.font-\[500\]) {
    font-weight: 500;
}
body :is(.font-bold) {
    font-weight: 700;
}
body :is(.font-light) {
    font-weight: 300;
}
body :is(.font-medium) {
    font-weight: 500;
}
body :is(.font-normal) {
    font-weight: 400;
}
body :is(.font-semibold) {
    font-weight: 600;
}
body :is(.font-thin) {
    font-weight: 100;
}
body :is(.lowercase) {
    text-transform: lowercase;
}
body :is(.capitalize) {
    text-transform: capitalize;
}
body :is(.normal-case) {
    text-transform: none;
}
body :is(.not-italic) {
    font-style: normal;
}
body :is(.leading-3) {
    line-height: .75rem;
}
body :is(.leading-6) {
    line-height: 1.5rem;
}
body :is(.leading-\[20px\]) {
    line-height: 20px;
}
body :is(.leading-\[3rem\]) {
    line-height: 3rem;
}
body :is(.text-\[\#0032C2\]) {
    --tw-text-opacity: 1;
    color: rgb(0 50 194 / var(--tw-text-opacity));
}
body :is(.text-black-900) {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
body :is(.text-blue-300) {
    --tw-text-opacity: 1;
    color: rgb(147 197 253 / var(--tw-text-opacity));
}
body :is(.text-blue-500) {
    --tw-text-opacity: 1;
    color: rgb(30 200 255 / var(--tw-text-opacity));
}
body :is(.text-gray-25) {
    --tw-text-opacity: 1;
    color: rgb(224 224 224 / var(--tw-text-opacity));
}
body :is(.text-gray-400) {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
}
body :is(.text-gray-450) {
    --tw-text-opacity: 1;
    color: rgb(205 205 205 / var(--tw-text-opacity));
}
body :is(.text-gray-50) {
    color: #FFFFFFCC;
}
body :is(.text-gray-500) {
    --tw-text-opacity: 1;
    color: rgb(184 184 184 / var(--tw-text-opacity));
}
body :is(.text-gray-550) {
    --tw-text-opacity: 1;
    color: rgb(96 96 96 / var(--tw-text-opacity));
}
body :is(.text-gray-600) {
    --tw-text-opacity: 1;
    color: rgb(179 179 179 / var(--tw-text-opacity));
}
body :is(.text-gray-650) {
    --tw-text-opacity: 1;
    color: rgb(189 188 187 / var(--tw-text-opacity));
}
body :is(.text-gray-800) {
    --tw-text-opacity: 1;
    color: rgb(158 158 176 / var(--tw-text-opacity));
}
body :is(.text-green-500) {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}
body :is(.text-red-500) {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}
body :is(.text-red-600) {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}
body :is(.text-white) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
body :is(.text-yellow-400) {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
}
body :is(.underline) {
    text-decoration-line: underline;
}
body :is(.line-through) {
    text-decoration-line: line-through;
}
body :is(.placeholder-white)::-moz-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}
body :is(.placeholder-white)::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(255 255 255 / var(--tw-placeholder-opacity));
}
body :is(.opacity-0) {
    opacity: 0;
}
body :is(.opacity-100) {
    opacity: 1;
}
body :is(.opacity-30) {
    opacity: 0.3;
}
body :is(.opacity-40) {
    opacity: 0.4;
}
body :is(.opacity-50) {
    opacity: 0.5;
}
body :is(.opacity-60) {
    opacity: 0.6;
}
body :is(.opacity-70) {
    opacity: 0.7;
}
body :is(.opacity-80) {
    opacity: 0.8;
}
body :is(.shadow-2xl) {
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
body :is(.shadow-phone) {
    --tw-shadow: 0px 0px 25px 5px #1ec8ff2e;
    --tw-shadow-colored: 0px 0px 25px 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
body :is(.shadow-transparent) {
    --tw-shadow-color: transparent;
    --tw-shadow: var(--tw-shadow-colored);
}
body :is(.outline-none) {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
body :is(.outline) {
    outline-style: solid;
}
body :is(.blur) {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
body :is(.drop-shadow-2xl) {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
body :is(.filter) {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
body :is(.transition-\[filter\]) {
    transition-property: filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
body :is(.transition-\[width\]) {
    transition-property: width;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
body :is(.transition-all) {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
body :is(.duration-100) {
    transition-duration: 100ms;
}
body :is(.duration-150) {
    transition-duration: 150ms;
}
body :is(.duration-300) {
    transition-duration: 300ms;
}
body :is(.scrollbar-hide) {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
body :is(.scrollbar-hide)::-webkit-scrollbar {
    display: none;
}
body :is(.\!scrollbar-default) {
    -ms-overflow-style: auto !important;
    scrollbar-width: auto !important;
}
body :is(.\!scrollbar-default)::-webkit-scrollbar {
    display: block !important;
}
body :is(.capitalize-first):first-letter {
    text-transform: uppercase;
}

html,
body {
    min-height: 100vh;
    background-color: rgb(27, 32, 35);
    margin: 0;
    padding: 0;
}

body::-webkit-scrollbar {
    width: 0;
    height: 0;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

::-webkit-scrollbar-track {
    background: #171C1F !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
    background: #353A3D !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #C0C8CD !important;
}

input:-moz-placeholder-shown {
    text-overflow: ellipsis;
}

input:placeholder-shown {
    text-overflow: ellipsis;
}

a:active {
    color: inherit;
}

a {
    color: inherit;
    text-decoration: none;
}

.swiper-pagination-bullet {
    background-color: #2c2f42 !important;
    width: 10px !important;
    height: 10px !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    width: 6px !important;
    height: 14px !important;
    border-radius: 3px !important;
    background-color: #146683 !important;
}

#auth-layout-slider .swiper-pagination-bullet-active {
    width: 10px !important;
    height: 10px !important;
    border-radius: 99px !important;
    background-color: #8ccff0 !important;
}


.text-stroke {
    -webkit-text-stroke-width: 0.8px;
    -webkit-text-stroke-color: #2f2f2f;
}


@media screen and (min-width: 1000px) {
    .swiper-button-prev::after,
    .swiper-button-next::after {
        display: flex !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: flex !important;
    }
}


@media screen and (min-width: 350px) {
    .swiper-container {
        width: 500px;
    }
}

@media screen and (min-width: 410px) {
    .swiper-container {
        width: 600px;
    }
}

@media screen and (min-width: 500px) {
    .swiper-container {
        width: 700px;
    }
}

@media screen and (min-width: 600px) {
    .swiper-container {
        width: 768px;
    }
}

@media screen and (min-width: 700px) {
    .swiper-container {
        width: 768px;
    }
}

@media screen and (min-width: 1000px) {
    .swiper-container {
        width: 1000px;
    }
}

@media screen and (min-width: 800px) {
    .swiper-container {
        width: 1300px;
    }
}

@media screen and (min-width: 1500px) {
    .swiper-container {
        width: 1600px;
    }
}

@media screen and (min-width: 2000px) {
    .swiper-container {
        width: 2000px;
    }
}

.scroller {
    -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
            mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller .scroller__inner {
    width: -moz-max-content;
    width: max-content;
    animation: scroll 20s linear infinite;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 1.25rem));
    }
}

@media (min-width: 600px) {
    .sm\:container {
        width: 100%;
    }
    @media (min-width: 0px) {
        .sm\:container {
            max-width: 0px;
        }
    }
    @media (min-width: 300px) {
        .sm\:container {
            max-width: 300px;
        }
    }
    @media (min-width: 400px) {
        .sm\:container {
            max-width: 400px;
        }
    }
    @media (min-width: 600px) {
        .sm\:container {
            max-width: 600px;
        }
    }
    @media (min-width: 905px) {
        .sm\:container {
            max-width: 905px;
        }
    }
    @media (min-width: 1024px) {
        .sm\:container {
            max-width: 1024px;
        }
    }
    @media (min-width: 1240px) {
        .sm\:container {
            max-width: 1240px;
        }
    }
    @media (min-width: 1440px) {
        .sm\:container {
            max-width: 1440px;
        }
    }
    @media (min-width: 1536px) {
        .sm\:container {
            max-width: 1536px;
        }
    }
    @media (min-width: 2500px) {
        .sm\:container {
            max-width: 2500px;
        }
    }
}

body :is(.first-letter\:text-left)::first-letter {
    text-align: left;
}

body :is(.first-letter\:uppercase)::first-letter {
    text-transform: uppercase;
}

body :is(.before\:space-y-10 > :not([hidden]) ~ :not([hidden]))::before {
    content: var(--tw-content);
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

body :is(.hover\:scale-125:hover) {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

body :is(.hover\:border-blue-500:hover) {
    --tw-border-opacity: 1;
    border-color: rgb(30 200 255 / var(--tw-border-opacity));
}

body :is(.hover\:bg-black-500:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(44 44 51 / var(--tw-bg-opacity));
}

body :is(.hover\:bg-gray-300:hover) {
    --tw-bg-opacity: 1;
    background-color: rgb(55 55 66 / var(--tw-bg-opacity));
}

body :is(.hover\:from-blue-600:hover) {
    --tw-gradient-from: #118DD7 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(17 141 215 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

body :is(.hover\:to-blue-500:hover) {
    --tw-gradient-to: #1EC8FF var(--tw-gradient-to-position);
}

body :is(.hover\:text-blue-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(30 200 255 / var(--tw-text-opacity));
}

body :is(.hover\:text-red-500:hover) {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity));
}

body :is(.hover\:text-white:hover) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

body :is(.hover\:underline:hover) {
    text-decoration-line: underline;
}

body :is(.hover\:opacity-100:hover) {
    opacity: 1;
}

@media (min-width: 0px) {
    body :is(.xs\:h-\[40px\]) {
        height: 40px;
    }
    body :is(.xs\:w-\[40px\]) {
        width: 40px;
    }
    body :is(.xs\:text-\[10px\]) {
        font-size: 10px;
    }
    body :is(.xs\:text-\[12px\]) {
        font-size: 12px;
    }
}

@media (min-width: 300px) {
    body :is(.ms\:h-\[64px\]) {
        height: 64px;
    }
    body :is(.ms\:w-\[64px\]) {
        width: 64px;
    }
    body :is(.ms\:text-\[12px\]) {
        font-size: 12px;
    }
    body :is(.ms\:text-\[14px\]) {
        font-size: 14px;
    }
}

@media (min-width: 400px) {
    body :is(.ms_2\:max-h-\[60vh\]) {
        max-height: 60vh;
    }
}

@media (min-width: 600px) {
    body :is(.sm\:mx-auto) {
        margin-left: auto;
        margin-right: auto;
    }
    body :is(.sm\:mb-8) {
        margin-bottom: 2rem;
    }
    body :is(.sm\:ml-4) {
        margin-left: 1rem;
    }
    body :is(.sm\:mt-0) {
        margin-top: 0px;
    }
    body :is(.sm\:flex) {
        display: flex;
    }
    body :is(.sm\:hidden) {
        display: none;
    }
    body :is(.sm\:h-24) {
        height: 6rem;
    }
    body :is(.sm\:h-56) {
        height: 14rem;
    }
    body :is(.sm\:h-9) {
        height: 2.25rem;
    }
    body :is(.sm\:h-96) {
        height: 24rem;
    }
    body :is(.sm\:h-\[40rem\]) {
        height: 40rem;
    }
    body :is(.sm\:h-\[96px\]) {
        height: 96px;
    }
    body :is(.sm\:h-fit) {
        height: -moz-fit-content;
        height: fit-content;
    }
    body :is(.sm\:h-full) {
        height: 100%;
    }
    body :is(.sm\:w-64) {
        width: 16rem;
    }
    body :is(.sm\:w-9) {
        width: 2.25rem;
    }
    body :is(.sm\:w-96) {
        width: 24rem;
    }
    body :is(.sm\:w-\[400px\]) {
        width: 400px;
    }
    body :is(.sm\:w-\[445px\]) {
        width: 445px;
    }
    body :is(.sm\:w-\[96px\]) {
        width: 96px;
    }
    body :is(.sm\:max-w-lg) {
        max-width: 32rem;
    }
    body :is(.sm\:max-w-none) {
        max-width: none;
    }
    body :is(.sm\:max-w-xs) {
        max-width: 20rem;
    }
    body :is(.sm\:grid-cols-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    body :is(.sm\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    body :is(.sm\:flex-row) {
        flex-direction: row;
    }
    body :is(.sm\:items-center) {
        align-items: center;
    }
    body :is(.sm\:gap-12) {
        gap: 3rem;
    }
    body :is(.sm\:gap-4) {
        gap: 1rem;
    }
    body :is(.sm\:p-5) {
        padding: 1.25rem;
    }
    body :is(.sm\:px-8) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    body :is(.sm\:py-0) {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    body :is(.sm\:text-sm) {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}

@media (min-width: 905px) {
    body :is(.md\:visible) {
        visibility: visible;
    }
    body :is(.md\:absolute) {
        position: absolute;
    }
    body :is(.md\:-bottom-3) {
        bottom: -0.75rem;
    }
    body :is(.md\:-bottom-\[150px\]) {
        bottom: -150px;
    }
    body :is(.md\:-bottom-\[300px\]) {
        bottom: -300px;
    }
    body :is(.md\:bottom-1\/2) {
        bottom: 50%;
    }
    body :is(.md\:left-1\/2) {
        left: 50%;
    }
    body :is(.md\:left-2) {
        left: 0.5rem;
    }
    body :is(.md\:left-4) {
        left: 1rem;
    }
    body :is(.md\:left-40) {
        left: 10rem;
    }
    body :is(.md\:left-5) {
        left: 1.25rem;
    }
    body :is(.md\:left-\[100px\]) {
        left: 100px;
    }
    body :is(.md\:left-auto) {
        left: auto;
    }
    body :is(.md\:right-0) {
        right: 0px;
    }
    body :is(.md\:top-1\/2) {
        top: 50%;
    }
    body :is(.md\:top-11) {
        top: 2.75rem;
    }
    body :is(.md\:top-14) {
        top: 3.5rem;
    }
    body :is(.md\:top-16) {
        top: 4rem;
    }
    body :is(.md\:top-5) {
        top: 1.25rem;
    }
    body :is(.md\:mx-0) {
        margin-left: 0px;
        margin-right: 0px;
    }
    body :is(.md\:mx-auto) {
        margin-left: auto;
        margin-right: auto;
    }
    body :is(.md\:my-0) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    body :is(.md\:my-20) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    body :is(.md\:my-auto) {
        margin-top: auto;
        margin-bottom: auto;
    }
    body :is(.md\:mb-10) {
        margin-bottom: 2.5rem;
    }
    body :is(.md\:mb-32) {
        margin-bottom: 8rem;
    }
    body :is(.md\:mb-4) {
        margin-bottom: 1rem;
    }
    body :is(.md\:mb-6) {
        margin-bottom: 1.5rem;
    }
    body :is(.md\:ml-0) {
        margin-left: 0px;
    }
    body :is(.md\:ml-10) {
        margin-left: 2.5rem;
    }
    body :is(.md\:ml-\[134px\]) {
        margin-left: 134px;
    }
    body :is(.md\:ml-\[41px\]) {
        margin-left: 41px;
    }
    body :is(.md\:mr-0) {
        margin-right: 0px;
    }
    body :is(.md\:mt-0) {
        margin-top: 0px;
    }
    body :is(.md\:mt-10) {
        margin-top: 2.5rem;
    }
    body :is(.md\:mt-20) {
        margin-top: 5rem;
    }
    body :is(.md\:mt-4) {
        margin-top: 1rem;
    }
    body :is(.md\:mt-8) {
        margin-top: 2rem;
    }
    body :is(.md\:mt-\[16px\]) {
        margin-top: 16px;
    }
    body :is(.md\:block) {
        display: block;
    }
    body :is(.md\:flex) {
        display: flex;
    }
    body :is(.md\:grid) {
        display: grid;
    }
    body :is(.md\:hidden) {
        display: none;
    }
    body :is(.md\:h-10) {
        height: 2.5rem;
    }
    body :is(.md\:h-2) {
        height: 0.5rem;
    }
    body :is(.md\:h-20) {
        height: 5rem;
    }
    body :is(.md\:h-32) {
        height: 8rem;
    }
    body :is(.md\:h-36) {
        height: 9rem;
    }
    body :is(.md\:h-8) {
        height: 2rem;
    }
    body :is(.md\:h-80) {
        height: 20rem;
    }
    body :is(.md\:h-\[128px\]) {
        height: 128px;
    }
    body :is(.md\:h-\[43rem\]) {
        height: 43rem;
    }
    body :is(.md\:h-auto) {
        height: auto;
    }
    body :is(.md\:w-1\/2) {
        width: 50%;
    }
    body :is(.md\:w-10) {
        width: 2.5rem;
    }
    body :is(.md\:w-12) {
        width: 3rem;
    }
    body :is(.md\:w-2) {
        width: 0.5rem;
    }
    body :is(.md\:w-2\/3) {
        width: 66.666667%;
    }
    body :is(.md\:w-20) {
        width: 5rem;
    }
    body :is(.md\:w-36) {
        width: 9rem;
    }
    body :is(.md\:w-4) {
        width: 1rem;
    }
    body :is(.md\:w-44) {
        width: 11rem;
    }
    body :is(.md\:w-72) {
        width: 18rem;
    }
    body :is(.md\:w-80) {
        width: 20rem;
    }
    body :is(.md\:w-96) {
        width: 24rem;
    }
    body :is(.md\:w-\[128px\]) {
        width: 128px;
    }
    body :is(.md\:w-\[30\%\]) {
        width: 30%;
    }
    body :is(.md\:w-\[35\%\]) {
        width: 35%;
    }
    body :is(.md\:w-\[400px\]) {
        width: 400px;
    }
    body :is(.md\:w-\[52rem\]) {
        width: 52rem;
    }
    body :is(.md\:w-\[530px\]) {
        width: 530px;
    }
    body :is(.md\:w-\[788px\]) {
        width: 788px;
    }
    body :is(.md\:w-\[80\%\]) {
        width: 80%;
    }
    body :is(.md\:w-auto) {
        width: auto;
    }
    body :is(.md\:w-full) {
        width: 100%;
    }
    body :is(.md\:min-w-max) {
        min-width: -moz-max-content;
        min-width: max-content;
    }
    body :is(.md\:max-w-\[200px\]) {
        max-width: 200px;
    }
    body :is(.md\:max-w-\[344px\]) {
        max-width: 344px;
    }
    body :is(.md\:max-w-none) {
        max-width: none;
    }
    body :is(.md\:max-w-sm) {
        max-width: 24rem;
    }
    body :is(.md\:flex-grow) {
        flex-grow: 1;
    }
    body :is(.md\:flex-grow-0) {
        flex-grow: 0;
    }
    body :is(.md\:-translate-x-1\/2) {
        --tw-translate-x: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.md\:-translate-y-1\/2) {
        --tw-translate-y: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.md\:translate-y-1\/2) {
        --tw-translate-y: 50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.md\:transform) {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.md\:grid-cols-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    body :is(.md\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    body :is(.md\:grid-cols-5) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    body :is(.md\:flex-row) {
        flex-direction: row;
    }
    body :is(.md\:items-start) {
        align-items: flex-start;
    }
    body :is(.md\:items-end) {
        align-items: flex-end;
    }
    body :is(.md\:justify-start) {
        justify-content: flex-start;
    }
    body :is(.md\:gap-x-4) {
        -moz-column-gap: 1rem;
             column-gap: 1rem;
    }
    body :is(.md\:space-x-2 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.md\:space-x-3 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.75rem * var(--tw-space-x-reverse));
        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.md\:space-x-4 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.md\:space-x-6 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.md\:space-x-\[16px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(16px * var(--tw-space-x-reverse));
        margin-left: calc(16px * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.md\:space-y-0 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }
    body :is(.md\:space-y-3 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
    }
    body :is(.md\:space-y-6 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
    }
    body :is(.md\:space-y-\[24px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(24px * var(--tw-space-y-reverse));
    }
    body :is(.md\:space-y-\[48px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(48px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(48px * var(--tw-space-y-reverse));
    }
    body :is(.md\:rounded-\[28px\]) {
        border-radius: 28px;
    }
    body :is(.md\:border) {
        border-width: 1px;
    }
    body :is(.md\:border-none) {
        border-style: none;
    }
    body :is(.md\:border-gray-900) {
        --tw-border-opacity: 1;
        border-color: rgb(39 39 44 / var(--tw-border-opacity));
    }
    body :is(.md\:bg-black-700) {
        --tw-bg-opacity: 1;
        background-color: rgb(24 24 29 / var(--tw-bg-opacity));
    }
    body :is(.md\:p-14) {
        padding: 3.5rem;
    }
    body :is(.md\:p-4) {
        padding: 1rem;
    }
    body :is(.md\:p-6) {
        padding: 1.5rem;
    }
    body :is(.md\:p-\[26px\]) {
        padding: 26px;
    }
    body :is(.md\:px-0) {
        padding-left: 0px;
        padding-right: 0px;
    }
    body :is(.md\:px-12) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    body :is(.md\:px-2) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    body :is(.md\:px-4) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    body :is(.md\:px-5) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
    body :is(.md\:px-6) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    body :is(.md\:px-7) {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
    body :is(.md\:px-8) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    body :is(.md\:py-14) {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
    body :is(.md\:py-3) {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    body :is(.md\:pb-0) {
        padding-bottom: 0px;
    }
    body :is(.md\:pb-2) {
        padding-bottom: 0.5rem;
    }
    body :is(.md\:pb-4) {
        padding-bottom: 1rem;
    }
    body :is(.md\:pb-8) {
        padding-bottom: 2rem;
    }
    body :is(.md\:pb-\[24px\]) {
        padding-bottom: 24px;
    }
    body :is(.md\:pr-4) {
        padding-right: 1rem;
    }
    body :is(.md\:pr-6) {
        padding-right: 1.5rem;
    }
    body :is(.md\:pt-10) {
        padding-top: 2.5rem;
    }
    body :is(.md\:pt-12) {
        padding-top: 3rem;
    }
    body :is(.md\:pt-20) {
        padding-top: 5rem;
    }
    body :is(.md\:text-2xl) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    body :is(.md\:text-4xl) {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    body :is(.md\:text-\[12px\]) {
        font-size: 12px;
    }
    body :is(.md\:text-\[28px\]) {
        font-size: 28px;
    }
    body :is(.md\:text-\[32px\]) {
        font-size: 32px;
    }
    body :is(.md\:text-\[3rem\]) {
        font-size: 3rem;
    }
    body :is(.md\:text-base) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    body :is(.md\:text-lg) {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    body :is(.md\:text-sm) {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    body :is(.md\:text-xl) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    body :is(.md\:text-xs) {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    body :is(.md\:font-medium) {
        font-weight: 500;
    }
    body :is(.md\:font-semibold) {
        font-weight: 600;
    }
    body :is(.md\:opacity-100) {
        opacity: 1;
    }
}

@media (min-width: 1024px) {
    body :is(.md_2\:mx-auto) {
        margin-left: auto;
        margin-right: auto;
    }
    body :is(.md_2\:mb-20) {
        margin-bottom: 5rem;
    }
    body :is(.md_2\:inline) {
        display: inline;
    }
    body :is(.md_2\:flex) {
        display: flex;
    }
    body :is(.md_2\:hidden) {
        display: none;
    }
    body :is(.md_2\:h-auto) {
        height: auto;
    }
    body :is(.md_2\:w-56) {
        width: 14rem;
    }
    body :is(.md_2\:w-full) {
        width: 100%;
    }
    body :is(.md_2\:flex-row) {
        flex-direction: row;
    }
    body :is(.md_2\:items-start) {
        align-items: flex-start;
    }
    body :is(.md_2\:items-center) {
        align-items: center;
    }
    body :is(.md_2\:justify-start) {
        justify-content: flex-start;
    }
    body :is(.md_2\:justify-between) {
        justify-content: space-between;
    }
    body :is(.md_2\:gap-6) {
        gap: 1.5rem;
    }
    body :is(.md_2\:space-x-14 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(3.5rem * var(--tw-space-x-reverse));
        margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.md_2\:rounded-4xl) {
        border-radius: 4rem;
    }
    body :is(.md_2\:px-0) {
        padding-left: 0px;
        padding-right: 0px;
    }
    body :is(.md_2\:px-\[1\.8rem\]) {
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }
    body :is(.md_2\:py-\[100px\]) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    body :is(.md_2\:text-left) {
        text-align: left;
    }
    body :is(.md_2\:text-\[4\.5rem\]) {
        font-size: 4.5rem;
    }
    body :is(.md_2\:text-lg) {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    body :is(.md_2\:text-xl) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    body :is(.md_2\:leading-\[5rem\]) {
        line-height: 5rem;
    }
}

@media (min-width: 1240px) {
    body :is(.lg\:static) {
        position: static;
    }
    body :is(.lg\:-bottom-\[110px\]) {
        bottom: -110px;
    }
    body :is(.lg\:-top-16) {
        top: -4rem;
    }
    body :is(.lg\:bottom-16) {
        bottom: 4rem;
    }
    body :is(.lg\:left-0) {
        left: 0px;
    }
    body :is(.lg\:left-1\/2) {
        left: 50%;
    }
    body :is(.lg\:left-\[21\%\]) {
        left: 21%;
    }
    body :is(.lg\:top-1\/2) {
        top: 50%;
    }
    body :is(.lg\:top-16) {
        top: 4rem;
    }
    body :is(.lg\:top-3\/4) {
        top: 75%;
    }
    body :is(.lg\:order-last) {
        order: 9999;
    }
    body :is(.lg\:m-0) {
        margin: 0px;
    }
    body :is(.lg\:mx-0) {
        margin-left: 0px;
        margin-right: 0px;
    }
    body :is(.lg\:mx-10) {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }
    body :is(.lg\:mx-auto) {
        margin-left: auto;
        margin-right: auto;
    }
    body :is(.lg\:my-0) {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    body :is(.lg\:my-20) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    body :is(.lg\:my-\[104px\]) {
        margin-top: 104px;
        margin-bottom: 104px;
    }
    body :is(.lg\:my-auto) {
        margin-top: auto;
        margin-bottom: auto;
    }
    body :is(.lg\:mb-0) {
        margin-bottom: 0px;
    }
    body :is(.lg\:mb-12) {
        margin-bottom: 3rem;
    }
    body :is(.lg\:mb-4) {
        margin-bottom: 1rem;
    }
    body :is(.lg\:mb-8) {
        margin-bottom: 2rem;
    }
    body :is(.lg\:mb-\[75px\]) {
        margin-bottom: 75px;
    }
    body :is(.lg\:ml-0) {
        margin-left: 0px;
    }
    body :is(.lg\:ml-16) {
        margin-left: 4rem;
    }
    body :is(.lg\:ml-2) {
        margin-left: 0.5rem;
    }
    body :is(.lg\:ml-44) {
        margin-left: 11rem;
    }
    body :is(.lg\:ml-\[100px\]) {
        margin-left: 100px;
    }
    body :is(.lg\:mr-0) {
        margin-right: 0px;
    }
    body :is(.lg\:mt-0) {
        margin-top: 0px;
    }
    body :is(.lg\:mt-12) {
        margin-top: 3rem;
    }
    body :is(.lg\:mt-16) {
        margin-top: 4rem;
    }
    body :is(.lg\:mt-24) {
        margin-top: 6rem;
    }
    body :is(.lg\:mt-4) {
        margin-top: 1rem;
    }
    body :is(.lg\:mt-40) {
        margin-top: 10rem;
    }
    body :is(.lg\:mt-6) {
        margin-top: 1.5rem;
    }
    body :is(.lg\:mt-\[30px\]) {
        margin-top: 30px;
    }
    body :is(.lg\:mt-\[35px\]) {
        margin-top: 35px;
    }
    body :is(.lg\:mt-\[5\.5rem\]) {
        margin-top: 5.5rem;
    }
    body :is(.lg\:block) {
        display: block;
    }
    body :is(.lg\:inline-block) {
        display: inline-block;
    }
    body :is(.lg\:inline) {
        display: inline;
    }
    body :is(.lg\:flex) {
        display: flex;
    }
    body :is(.lg\:hidden) {
        display: none;
    }
    body :is(.lg\:h-14) {
        height: 3.5rem;
    }
    body :is(.lg\:h-16) {
        height: 4rem;
    }
    body :is(.lg\:h-24) {
        height: 6rem;
    }
    body :is(.lg\:h-32) {
        height: 8rem;
    }
    body :is(.lg\:h-4) {
        height: 1rem;
    }
    body :is(.lg\:h-40) {
        height: 10rem;
    }
    body :is(.lg\:h-56) {
        height: 14rem;
    }
    body :is(.lg\:h-60) {
        height: 15rem;
    }
    body :is(.lg\:h-72) {
        height: 18rem;
    }
    body :is(.lg\:h-8) {
        height: 2rem;
    }
    body :is(.lg\:h-96) {
        height: 24rem;
    }
    body :is(.lg\:h-\[110px\]) {
        height: 110px;
    }
    body :is(.lg\:h-\[18\.75rem\]) {
        height: 18.75rem;
    }
    body :is(.lg\:h-\[300px\]) {
        height: 300px;
    }
    body :is(.lg\:h-\[36px\]) {
        height: 36px;
    }
    body :is(.lg\:h-\[390px\]) {
        height: 390px;
    }
    body :is(.lg\:h-\[400px\]) {
        height: 400px;
    }
    body :is(.lg\:h-\[43px\]) {
        height: 43px;
    }
    body :is(.lg\:h-\[450px\]) {
        height: 450px;
    }
    body :is(.lg\:h-\[46px\]) {
        height: 46px;
    }
    body :is(.lg\:h-\[53rem\]) {
        height: 53rem;
    }
    body :is(.lg\:h-\[56px\]) {
        height: 56px;
    }
    body :is(.lg\:h-auto) {
        height: auto;
    }
    body :is(.lg\:h-full) {
        height: 100%;
    }
    body :is(.lg\:max-h-\[500px\]) {
        max-height: 500px;
    }
    body :is(.lg\:max-h-\[90vh\]) {
        max-height: 90vh;
    }
    body :is(.lg\:min-h-full) {
        min-height: 100%;
    }
    body :is(.lg\:w-1\/2) {
        width: 50%;
    }
    body :is(.lg\:w-1\/3) {
        width: 33.333333%;
    }
    body :is(.lg\:w-16) {
        width: 4rem;
    }
    body :is(.lg\:w-2\/3) {
        width: 66.666667%;
    }
    body :is(.lg\:w-2\/4) {
        width: 50%;
    }
    body :is(.lg\:w-24) {
        width: 6rem;
    }
    body :is(.lg\:w-3\/4) {
        width: 75%;
    }
    body :is(.lg\:w-3\/5) {
        width: 60%;
    }
    body :is(.lg\:w-32) {
        width: 8rem;
    }
    body :is(.lg\:w-4) {
        width: 1rem;
    }
    body :is(.lg\:w-40) {
        width: 10rem;
    }
    body :is(.lg\:w-48) {
        width: 12rem;
    }
    body :is(.lg\:w-5\/6) {
        width: 83.333333%;
    }
    body :is(.lg\:w-60) {
        width: 15rem;
    }
    body :is(.lg\:w-64) {
        width: 16rem;
    }
    body :is(.lg\:w-8) {
        width: 2rem;
    }
    body :is(.lg\:w-80) {
        width: 20rem;
    }
    body :is(.lg\:w-85) {
        width: 22.5rem;
    }
    body :is(.lg\:w-96) {
        width: 24rem;
    }
    body :is(.lg\:w-\[110px\]) {
        width: 110px;
    }
    body :is(.lg\:w-\[12\%\]) {
        width: 12%;
    }
    body :is(.lg\:w-\[220px\]) {
        width: 220px;
    }
    body :is(.lg\:w-\[265\.6px\]) {
        width: 265.6px;
    }
    body :is(.lg\:w-\[28rem\]) {
        width: 28rem;
    }
    body :is(.lg\:w-\[350px\]) {
        width: 350px;
    }
    body :is(.lg\:w-\[36px\]) {
        width: 36px;
    }
    body :is(.lg\:w-\[445px\]) {
        width: 445px;
    }
    body :is(.lg\:w-\[450px\]) {
        width: 450px;
    }
    body :is(.lg\:w-\[46px\]) {
        width: 46px;
    }
    body :is(.lg\:w-\[50\%\]) {
        width: 50%;
    }
    body :is(.lg\:w-\[50vw\]) {
        width: 50vw;
    }
    body :is(.lg\:w-\[65vw\]) {
        width: 65vw;
    }
    body :is(.lg\:w-\[678px\]) {
        width: 678px;
    }
    body :is(.lg\:w-\[70\%\]) {
        width: 70%;
    }
    body :is(.lg\:w-\[70\.5em\]) {
        width: 70.5em;
    }
    body :is(.lg\:w-\[70rem\]) {
        width: 70rem;
    }
    body :is(.lg\:w-\[744px\]) {
        width: 744px;
    }
    body :is(.lg\:w-\[79\%\]) {
        width: 79%;
    }
    body :is(.lg\:w-\[844px\]) {
        width: 844px;
    }
    body :is(.lg\:w-\[85\%\]) {
        width: 85%;
    }
    body :is(.lg\:w-\[calc\(100\%-4\.75rem\)\]) {
        width: calc(100% - 4.75rem);
    }
    body :is(.lg\:w-auto) {
        width: auto;
    }
    body :is(.lg\:w-full) {
        width: 100%;
    }
    body :is(.lg\:min-w-\[477px\]) {
        min-width: 477px;
    }
    body :is(.lg\:max-w-\[20rem\]) {
        max-width: 20rem;
    }
    body :is(.lg\:max-w-\[250px\]) {
        max-width: 250px;
    }
    body :is(.lg\:max-w-\[312px\]) {
        max-width: 312px;
    }
    body :is(.lg\:max-w-\[448px\]) {
        max-width: 448px;
    }
    body :is(.lg\:max-w-\[560px\]) {
        max-width: 560px;
    }
    body :is(.lg\:max-w-\[70rem\]) {
        max-width: 70rem;
    }
    body :is(.lg\:max-w-\[none\]) {
        max-width: none;
    }
    body :is(.lg\:max-w-lg) {
        max-width: 32rem;
    }
    body :is(.lg\:flex-1) {
        flex: 1 1 0%;
    }
    body :is(.lg\:-translate-x-1\/2) {
        --tw-translate-x: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.lg\:-translate-y-1\/2) {
        --tw-translate-y: -50%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.lg\:scale-150) {
        --tw-scale-x: 1.5;
        --tw-scale-y: 1.5;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.lg\:transform) {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    body :is(.lg\:grid-cols-2) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    body :is(.lg\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    body :is(.lg\:grid-cols-4) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    body :is(.lg\:grid-cols-6) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    body :is(.lg\:flex-row) {
        flex-direction: row;
    }
    body :is(.lg\:flex-row-reverse) {
        flex-direction: row-reverse;
    }
    body :is(.lg\:flex-col) {
        flex-direction: column;
    }
    body :is(.lg\:items-start) {
        align-items: flex-start;
    }
    body :is(.lg\:items-end) {
        align-items: flex-end;
    }
    body :is(.lg\:items-center) {
        align-items: center;
    }
    body :is(.lg\:items-stretch) {
        align-items: stretch;
    }
    body :is(.lg\:justify-center) {
        justify-content: center;
    }
    body :is(.lg\:justify-between) {
        justify-content: space-between;
    }
    body :is(.lg\:justify-around) {
        justify-content: space-around;
    }
    body :is(.lg\:gap-24) {
        gap: 6rem;
    }
    body :is(.lg\:gap-3) {
        gap: 0.75rem;
    }
    body :is(.lg\:gap-4) {
        gap: 1rem;
    }
    body :is(.lg\:gap-5) {
        gap: 1.25rem;
    }
    body :is(.lg\:gap-6) {
        gap: 1.5rem;
    }
    body :is(.lg\:gap-8) {
        gap: 2rem;
    }
    body :is(.lg\:gap-\[3\.75rem\]) {
        gap: 3.75rem;
    }
    body :is(.lg\:space-x-0 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-10 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2.5rem * var(--tw-space-x-reverse));
        margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-2 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-28 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(7rem * var(--tw-space-x-reverse));
        margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-3 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0.75rem * var(--tw-space-x-reverse));
        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-4 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-6 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-7 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.75rem * var(--tw-space-x-reverse));
        margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-8 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2rem * var(--tw-space-x-reverse));
        margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-x-\[24px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(24px * var(--tw-space-x-reverse));
        margin-left: calc(24px * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.lg\:space-y-0 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-10 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-12 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(3rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-3 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-5 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-6 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-7 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-8 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(2rem * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-\[26px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(26px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(26px * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-\[32px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(32px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(32px * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-\[40px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(40px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(40px * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-\[50px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(50px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(50px * var(--tw-space-y-reverse));
    }
    body :is(.lg\:space-y-\[5px\] > :not([hidden]) ~ :not([hidden])) {
        --tw-space-y-reverse: 0;
        margin-top: calc(5px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(5px * var(--tw-space-y-reverse));
    }
    body :is(.lg\:overflow-visible) {
        overflow: visible;
    }
    body :is(.lg\:overflow-y-hidden) {
        overflow-y: hidden;
    }
    body :is(.lg\:overflow-y-scroll) {
        overflow-y: scroll;
    }
    body :is(.lg\:rounded-2xl) {
        border-radius: 1rem;
    }
    body :is(.lg\:rounded-4xl) {
        border-radius: 4rem;
    }
    body :is(.lg\:rounded-\[28px\]) {
        border-radius: 28px;
    }
    body :is(.lg\:rounded-\[3rem\]) {
        border-radius: 3rem;
    }
    body :is(.lg\:rounded-none) {
        border-radius: 0px;
    }
    body :is(.lg\:rounded-b-2xl) {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    body :is(.lg\:rounded-l-2xl) {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
    body :is(.lg\:rounded-l-none) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    body :is(.lg\:rounded-r-2xl) {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
    body :is(.lg\:rounded-r-none) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    body :is(.lg\:rounded-r-xl) {
        border-top-right-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
    }
    body :is(.lg\:rounded-t-2xl) {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    body :is(.lg\:rounded-t-3xl) {
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
    }
    body :is(.lg\:border-0) {
        border-width: 0px;
    }
    body :is(.lg\:border-4) {
        border-width: 4px;
    }
    body :is(.lg\:border-b) {
        border-bottom-width: 1px;
    }
    body :is(.lg\:border-none) {
        border-style: none;
    }
    body :is(.lg\:border-blue-500) {
        --tw-border-opacity: 1;
        border-color: rgb(30 200 255 / var(--tw-border-opacity));
    }
    body :is(.lg\:bg-black-700) {
        --tw-bg-opacity: 1;
        background-color: rgb(24 24 29 / var(--tw-bg-opacity));
    }
    body :is(.lg\:p-4) {
        padding: 1rem;
    }
    body :is(.lg\:p-6) {
        padding: 1.5rem;
    }
    body :is(.lg\:p-\[24px\]) {
        padding: 24px;
    }
    body :is(.lg\:p-\[46px\]) {
        padding: 46px;
    }
    body :is(.lg\:px-0) {
        padding-left: 0px;
        padding-right: 0px;
    }
    body :is(.lg\:px-1) {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    body :is(.lg\:px-16) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    body :is(.lg\:px-2) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    body :is(.lg\:px-20) {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    body :is(.lg\:px-36) {
        padding-left: 9rem;
        padding-right: 9rem;
    }
    body :is(.lg\:px-48) {
        padding-left: 12rem;
        padding-right: 12rem;
    }
    body :is(.lg\:px-6) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    body :is(.lg\:px-8) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    body :is(.lg\:px-\[16px\]) {
        padding-left: 16px;
        padding-right: 16px;
    }
    body :is(.lg\:px-\[46px\]) {
        padding-left: 46px;
        padding-right: 46px;
    }
    body :is(.lg\:px-\[52px\]) {
        padding-left: 52px;
        padding-right: 52px;
    }
    body :is(.lg\:px-\[6px\]) {
        padding-left: 6px;
        padding-right: 6px;
    }
    body :is(.lg\:py-20) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    body :is(.lg\:py-3) {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    body :is(.lg\:py-4) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    body :is(.lg\:py-5) {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    body :is(.lg\:py-6) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    body :is(.lg\:py-7) {
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
    }
    body :is(.lg\:py-8) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    body :is(.lg\:py-\[20px\]) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    body :is(.lg\:py-\[36px\]) {
        padding-top: 36px;
        padding-bottom: 36px;
    }
    body :is(.lg\:pb-0) {
        padding-bottom: 0px;
    }
    body :is(.lg\:pb-6) {
        padding-bottom: 1.5rem;
    }
    body :is(.lg\:pl-0) {
        padding-left: 0px;
    }
    body :is(.lg\:pl-8) {
        padding-left: 2rem;
    }
    body :is(.lg\:pl-\[18\%\]) {
        padding-left: 18%;
    }
    body :is(.lg\:pr-0) {
        padding-right: 0px;
    }
    body :is(.lg\:pr-3) {
        padding-right: 0.75rem;
    }
    body :is(.lg\:pt-0) {
        padding-top: 0px;
    }
    body :is(.lg\:pt-10) {
        padding-top: 2.5rem;
    }
    body :is(.lg\:pt-16) {
        padding-top: 4rem;
    }
    body :is(.lg\:pt-40) {
        padding-top: 10rem;
    }
    body :is(.lg\:pt-6) {
        padding-top: 1.5rem;
    }
    body :is(.lg\:text-left) {
        text-align: left;
    }
    body :is(.lg\:text-start) {
        text-align: start;
    }
    body :is(.lg\:text-2xl) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
    body :is(.lg\:text-3xl) {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    body :is(.lg\:text-4xl) {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    body :is(.lg\:text-6xl) {
        font-size: 3.75rem;
        line-height: 1;
    }
    body :is(.lg\:text-\[20px\]) {
        font-size: 20px;
    }
    body :is(.lg\:text-\[24px\]) {
        font-size: 24px;
    }
    body :is(.lg\:text-\[28px\]) {
        font-size: 28px;
    }
    body :is(.lg\:text-\[3\.5rem\]) {
        font-size: 3.5rem;
    }
    body :is(.lg\:text-\[36px\]) {
        font-size: 36px;
    }
    body :is(.lg\:text-\[5rem\]) {
        font-size: 5rem;
    }
    body :is(.lg\:text-base) {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    body :is(.lg\:text-lg) {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    body :is(.lg\:text-sm) {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    body :is(.lg\:text-xl) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    body :is(.lg\:font-medium) {
        font-weight: 500;
    }
    body :is(.lg\:leading-\[5rem\]) {
        line-height: 5rem;
    }
    body :is(.lg\:no-underline) {
        text-decoration-line: none;
    }
    body :is(.lg\:opacity-80) {
        opacity: 0.8;
    }
    body :is(.lg\:scrollbar-hide) {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    body :is(.lg\:scrollbar-hide)::-webkit-scrollbar {
        display: none;
    }
    body :is(.lg\:scrollbar-default) {
        -ms-overflow-style: auto;
        scrollbar-width: auto;
    }
    body :is(.lg\:scrollbar-default)::-webkit-scrollbar {
        display: block;
    }
    body :is(.lg\:hover\:border-blue-500:hover) {
        --tw-border-opacity: 1;
        border-color: rgb(30 200 255 / var(--tw-border-opacity));
    }
    body :is(.lg\:hover\:from-blue-600:hover) {
        --tw-gradient-from: #118DD7 var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(17 141 215 / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }
    body :is(.lg\:hover\:to-blue-500:hover) {
        --tw-gradient-to: #1EC8FF var(--tw-gradient-to-position);
    }
    body :is(.lg\:hover\:text-blue-500:hover) {
        --tw-text-opacity: 1;
        color: rgb(30 200 255 / var(--tw-text-opacity));
    }
    body :is(.lg\:hover\:underline:hover) {
        text-decoration-line: underline;
    }
    body :is(.lg\:hover\:opacity-100:hover) {
        opacity: 1;
    }
    body :is(.lg\:hover\:shadow-lg:hover) {
        --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
}

@media (min-width: 1440px) {
    body :is(.xl\:ml-6) {
        margin-left: 1.5rem;
    }
    body :is(.xl\:block) {
        display: block;
    }
    body :is(.xl\:flex) {
        display: flex;
    }
    body :is(.xl\:hidden) {
        display: none;
    }
    body :is(.xl\:w-60) {
        width: 15rem;
    }
    body :is(.xl\:w-80) {
        width: 20rem;
    }
    body :is(.xl\:w-\[25rem\]) {
        width: 25rem;
    }
    body :is(.xl\:w-\[30\%\]) {
        width: 30%;
    }
    body :is(.xl\:w-\[36\.5rem\]) {
        width: 36.5rem;
    }
    body :is(.xl\:w-\[70\%\]) {
        width: 70%;
    }
    body :is(.xl\:w-full) {
        width: 100%;
    }
    body :is(.xl\:grid-cols-3) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    body :is(.xl\:grid-cols-7) {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    body :is(.xl\:gap-6) {
        gap: 1.5rem;
    }
    body :is(.xl\:gap-7) {
        gap: 1.75rem;
    }
    body :is(.xl\:space-x-6 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(1.5rem * var(--tw-space-x-reverse));
        margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.xl\:space-x-8 > :not([hidden]) ~ :not([hidden])) {
        --tw-space-x-reverse: 0;
        margin-right: calc(2rem * var(--tw-space-x-reverse));
        margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
    }
    body :is(.xl\:bg-black-700) {
        --tw-bg-opacity: 1;
        background-color: rgb(24 24 29 / var(--tw-bg-opacity));
    }
    body :is(.xl\:px-16) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    body :is(.xl\:text-\[14px\]) {
        font-size: 14px;
    }
    body :is(.xl\:text-\[16px\]) {
        font-size: 16px;
    }
}

@media (min-width: 1536px) {
    body :is(.\32xl\:ml-0) {
        margin-left: 0px;
    }
    body :is(.\32xl\:w-40) {
        width: 10rem;
    }
    body :is(.\32xl\:w-\[15\%\]) {
        width: 15%;
    }
    body :is(.\32xl\:w-\[220px\]) {
        width: 220px;
    }
    body :is(.\32xl\:w-\[28rem\]) {
        width: 28rem;
    }
    body :is(.\32xl\:w-\[550px\]) {
        width: 550px;
    }
    body :is(.\32xl\:w-\[80\%\]) {
        width: 80%;
    }
    body :is(.\32xl\:max-w-\[1600px\]) {
        max-width: 1600px;
    }
    body :is(.\32xl\:grid-cols-5) {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    body :is(.\32xl\:grid-cols-6) {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    body :is(.\32xl\:rounded-\[4rem\]) {
        border-radius: 4rem;
    }
    body :is(.\32xl\:rounded-none) {
        border-radius: 0px;
    }
    body :is(.\32xl\:px-40) {
        padding-left: 10rem;
        padding-right: 10rem;
    }
    body :is(.\32xl\:px-60) {
        padding-left: 15rem;
        padding-right: 15rem;
    }
    body :is(.\32xl\:pl-\[15\%\]) {
        padding-left: 15%;
    }
}

@media (min-width: 2500px) {
    body :is(.\33xl\:h-\[30rem\]) {
        height: 30rem;
    }
    body :is(.\33xl\:max-h-\[400px\]) {
        max-height: 400px;
    }
    body :is(.\33xl\:w-\[20\%\]) {
        width: 20%;
    }
    body :is(.\33xl\:w-\[50vw\]) {
        width: 50vw;
    }
    body :is(.\33xl\:w-\[80\%\]) {
        width: 80%;
    }
    body :is(.\33xl\:grid-cols-7) {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }
    body :is(.\33xl\:grid-cols-9) {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }
    body :is(.\33xl\:py-\[56px\]) {
        padding-top: 56px;
        padding-bottom: 56px;
    }
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/f54d3b402c212b9e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/7108afb8b1381ad1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/7ede3623c9ddac57-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/_next/static/media/9e82d62334b205f4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/6c9a125e97d835e1-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/4c285fdca692ea22-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/f10b8e9d91f3edcb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/8888a3826f4a3af4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_4de79c';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_4de79c';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_4de79c {font-family: '__Poppins_4de79c', '__Poppins_Fallback_4de79c';font-style: normal
}.__variable_4de79c {--poppins: '__Poppins_4de79c', '__Poppins_Fallback_4de79c'
}

